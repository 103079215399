  .from {
    width: 95%;
    background-color: white;
    margin: auto;
    overflow: hidden;

    .h1 {
      font-size: 25px;
      text-align: center;
      margin: 20px auto;
    }

    .btns {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 150px;
      }
    }
  }