.all {
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  :global {

    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>td,
    .ant-table tfoot>tr>th {
      padding: 4px;
      font-size: small;
    }


    .ant-table-thead>tr>th {
      color: rgb(12, 12, 12);
      background-color: #5656d2a9;
    }

  }

}