.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .text {
    padding: 5%;
    font-size: 15px;
    text-align: center;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.815);
    font-weight: bolder;
  }

}