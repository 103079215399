.all {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .uplode {
    width: 30%;
    float: left;
  }
  .cards {
    width: 100%;
    height:70vh;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 5px;
    padding-bottom: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto; /* 确保内容可以垂直滚动 */
  }
  :global {

    .ant-table-tbody>tr>td,
    .ant-table-thead>tr>th,
    .ant-table tfoot>tr>td,
    .ant-table tfoot>tr>th {
      padding: 4px;
      font-size: small;
    }


    .ant-table-thead>tr>th {
      color: rgb(12, 12, 12);
      background-color: #0d4999a9;
    }

  }

}